<template>
  <div>
   
     <embed
          :src="src"
          :type="embedType"
          
          :id="type == 'pdf' ? 'embed-pdf' : ''"
        />
    <!-- <embed
      :src="'data:application/' + type + ';base64,' + base64"
      type="application/pdf"
      width="100%"
      id="embed-pdf"
    /> -->
  </div>
</template>

<script>
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";
import fileDownload from "js-file-download";

export default {
  components: { PDFViewer },
  data() {
    return {
      embedType: "",
      src: "",
    };
  },
  created() {
    console.log(this.type)
    if (this.type == "pdf") {
      this.src =
        "data:application/" +
        this.type +
        ";base64," +
        this.base64 
      
      this.embedType = "application/" + this.type;
    } else {
      this.src =
        "data:image/" + this.type + ";base64," + this.base64;
      this.embedType = "image/" + this.type;
    }

   
  },
  computed: {
    type() {
      return this.$store.state.app.fileType;
    },
    base64() {
      return this.$store.state.app.fileBase64;
    },
  },

  methods: {
    handleDownload() {},
  },
};
</script>